import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

import {useTranslation} from "react-i18next";



const pricingData = [
  {
    id: 1,
    plan: 'Aditamentos',
    price: '',
    features: ['a','b','c','d','e','f'],
    link: '#'
  },
  {
    id: 2,
    plan: 'Servicios',
    price: '',
    features: ['a','b','c','d','e','f'],
    link: '#'
  },
  {
    id: 3,
    plan: 'Complementos',
    price: '',
    features: ['a','b','c','d','e','f'],
    link: '#'
  }
]




function AppPricing() {
  const [t, i18n] = useTranslation("global")
  return (
    <section id="pricing" className="block pricing-block">
      <Container fluid>
        <div className="title-holder">
          <h2>{t("planreservacion.titulo_1")}</h2>
          <div className="subtitle">{t("planreservacion.subtitulo_1")}</div>
          
          <hr></hr>

        </div>

<Row>
          {
            pricingData.map(pricing => {
              return (
                <Col sm={4} key={pricing.id}>
                  <div className='heading'>
                    <h3>{t("planreservacion.titulo_a_" + pricing.id)}</h3>
                    <span className='price'>{pricing.price}</span>
                  </div>
                  <div className='content'>
                    <ListGroup>
                      {
                        pricing.features.map((feature, index) => {
                          return (
                            <ListGroup.Item key={index}>{t("planreservacion.seccion_" + feature + pricing.id)}</ListGroup.Item>
                                
                          );
                        })
                      }
                    </ListGroup>
                  </div>
                  <div className='btn-holder'>
                    <a href={pricing.link} className='btn btn-primary'>{t("planreservacion.todas")}</a>
                  </div>
                </Col>
              )
            })
          }
        </Row>


<hr></hr>



      </Container>
    </section>
  )
}

export default AppPricing;