import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


import img1 from '../assets/images/img1.jpg';

import {useTranslation} from "react-i18next";


function AppAbout() {
  const html = 80;
  const responsive = 95;
  const photoshop = 60;

  const [t, i18n] = useTranslation("global")
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>{t("seccion_nosotros.titulo")}</h2>
          <div className="subtitle">{t("seccion_nosotros.subtitulo")}</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>

<hr></hr>

{/*   acordeon   */}
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      {t("seccion_nosotros.titulo_seccion_a")}
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">{t("seccion_nosotros.subtitulo_seccion_a")}</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      {t("seccion_nosotros.titulo_seccion_b")}
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">{t("seccion_nosotros.subtitulo_seccion_b")}</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      {t("seccion_nosotros.titulo_seccion_c")}
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">{t("seccion_nosotros.subtitulo_seccion_c")}</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
      data-bs-target="#flush-collapseTree" aria-expanded="false" aria-controls="flush-collapseTree">
        {t("seccion_nosotros.titulo_seccion_d")}
      </button>
    </h2>
    <div id="flush-collapseTree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">{t("seccion_nosotros.subtitulo_seccion_d")}</div>
    </div>
  </div>
</div>

{/* fin acordeon   */}






          </Col>
        </Row>
      </Container>

      <hr></hr>
    </section>
  );
}

export default AppAbout;