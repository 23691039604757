import Carousel from 'react-bootstrap/Carousel';

import {useTranslation} from "react-i18next";




var heroData = [
  {
    id: 1,
    image: require('../assets/images/img-hero1.jpg'),
    title: '',
    description: '',
    link: '#'
  },
  {
    id: 2,
    image: require('../assets/images/img-hero2.jpg'),
    title: 'Difrute de su Estancia con Nosotros',
    description: 'Hotel Boutique un Concepto Inovador',
    link: '#'
  },
  {
    id: 3,
    image: require('../assets/images/img-hero3.jpg'),
    title: 'Conoce nuestras Instalaciones',
    description: 'Comodidad y Confort',
    link: '#'
  }
]

function AppHero() {
  const [t, i18n] = useTranslation("global")  
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{t("slide_titulo.titulo_" + hero.id)}</h2>
                    <p>{t("slide_subtitulo.titulo_" + hero.id)}</p>
                    
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;