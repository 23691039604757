import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import {useTranslation} from "react-i18next";

const blogData = [
  {
    id: 1,
    image: require('../assets/images/suite_a.jpg'),
    time: 'TRIPLE: TRES ADULTOS Y UN MENOR DE SIETE AÑOS   1 Cama KING SIZE / 1 Cama INDIVIDUAL',
    title: 'LAGUNA',
    description: '$1,750 / Noche',
    link: 'https://portafideihotelboutique.com/1'
  },
  {
    id: 2,
    image: require('../assets/images/suite_b.jpg'),
    time: 'DOBLE: DOS ADULTOS Y UN MENOR DE SIETE AÑOS    1 Cama KING SIZE',
    title: 'MIRADOR',
    description: '$ 1,200 P/Noche',
    link: 'https://portafideihotelboutique.com/2'
  },
  {
    id: 3,
    image: require('../assets/images/suite_c.jpg'),
    time: 'DOBLE: DOS ADULTOS Y UN MENOR DE SIETE AÑOS   1 Cama KING SIZE',
    title: 'QUEBRADA',
    description: '$ 1,200 P/Noche',
    link: 'https://portafideihotelboutique.com/3'
  },
  {
    id: 4,
    image: require('../assets/images/suite_d.jpg'),
    time: 'DOBLE: DOS ADULTOS Y UN MENOR DE SIETE AÑOS    1 Cama KING SIZE',
    title: 'TURRON',
    description: '$ 1,200 P/Noche',
    link: 'https://portafideihotelboutique.com/4'
  },
  {
    id: 5,
    image: require('../assets/images/suite_e.jpg'),
    time: 'DOBLE: DOS ADULTOS Y UN MENOR DE SIETE AÑOS    1 Cama KING SIZE',
    title: 'DUNA',
    description: '$ 1,200 P/Noche',
    link: 'https://portafideihotelboutique.com/5'
  },
  {
    id: 6,
    image: require('../assets/images/suite_f.jpg'),
    time: 'DOBLE: DOS ADULTOS Y UN MENOR DE SIETE AÑOS    2 Camas INDIVIDUALES',
    title: 'QUIAHUIZTLAN',
    description: '$ 1,200 P/Noche',
    link: 'https://portafideihotelboutique.com/6'
  }
]

function AppBlog() {
  const [t, i18n] = useTranslation("global")
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nuestras Habitaciones</h2>
          <div className="subtitle">Disfrute de su Estancia con Nosotros</div>
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div className='container text-center'>

                  
 
                  <form method="POST" action='reserva.php'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                      <Card.Title>{blog.title}</Card.Title>
                        <time>{t("recamaras_" + blog.id + ".descripcion")}</time>
                        <hr></hr>
                        <Card.Text>
                          <a className="btn btn-primary">{blog.description}<i className="fas fa-chevron-right"></i></a>
                        </Card.Text>
                        <hr></hr>
                        <p>{t("recamaras_" + blog.id + ".ingreso")} - <input type='date' name='cmb_checkin' ></input></p>
                        <p>{t("recamaras_" + blog.id + ".salida")} - <input type='date' name='cmb_checout' ></input></p>
                        <input type="hidden" name="txt_habitacion" size="10" value={blog.title} />
                        <input type="hidden" name="txt_pago" size="10" value={blog.description} />
                        <button type="submit" class="btn btn-warning">Consultar</button>
                      </Card.Body>
                      <hr></hr>
                    </Card>
                    </form>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
      <hr></hr>
    </section>
  )
}

export default AppBlog;