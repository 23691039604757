import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function AppContact() {
  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contactenos</h2>
          <div className="subtitle">Tu opinión es lo más Importante</div>
        </div>
        <Form className='contact-form' method="POST" action='correo/ejemplo.php'>
          <Row>
            <Col sm={4}>
              <Form.Control type="text" placeholder="Nombre Persona / Empresa" name='txt_nombre' required />
            </Col>
            <Col sm={4}>
              <Form.Control type="email" placeholder="Corre de contacto" name='txt_correo' required />
            </Col>
            <Col sm={4}>
              <Form.Control type="tel" placeholder="Número de Contacto" name='txt_numero' required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control as="textarea" placeholder="Dejanos un Comentario" name='txt_comentario' required />
            </Col>
          </Row>
          <div className='btn-holder'>
            <Button type="submit">Enviar</Button>
          </div>
        </Form>
      </Container>
       <div className='google-map'>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3757.001642137485!2d-96.40257922578272!3d19.669935333060618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85dca724824f9907%3A0xcaa98591d658aa5f!2sPorta%20Fidei%20Hotel%20Boutique!5e0!3m2!1ses!2smx!4v1688825050236!5m2!1ses!2smx"></iframe>
      </div>



      <Container fluid>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              contacto@portafideihotelboutique.com
            </li>
            <li>
              <i className="fas fa-phone"></i>
              Mex.(+521) 5539701052
            </li>
            <li>
              <a href='https://goo.gl/maps/7aPgr6j9DsKanp9u8'><i className="fas fa-map-marker-alt"></i></a>
              <a href='https://goo.gl/maps/7aPgr6j9DsKanp9u8'>Playa Villarica. Actopan. Veracruz, México.</a>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;