import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {useTranslation} from "react-i18next";


const servicesData = [
  {
    id: 1,
    icon: 'fas fa-umbrella-beach',
    title: 'Acceso a Playa',
    description: ''
  },
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: 'Alberca',
    description: ''
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: 'Area de Descanso',
    description: ''
  },
  {
    id: 4,
    icon: 'fas fa-desktop',
    title: 'Sala de Televisión',
    description: ''
  },
  {
    id: 5,
    icon: 'fas fa-trophy',
    title: 'Restaurante',
    description: ''
  },
  {
    id: 6,
    icon: 'fas fa-life-ring',
    title: 'Sala de Lectura',
    description: ''
  },
  {
    id: 7,
    icon: 'fas fa-life-ring',
    title: 'Jacuzzi',
    description: ''
  },
  {
    id: 8,
    icon: 'fas fa-life-ring',
    title: 'Areas Comunes',
    description: ''
  }
]






function AppServices() {
  const [t, i18n] = useTranslation("global")
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>{t("seccion_nuestros_servicios.titulo")}</h2>
          <div className="subtitle">{t("seccion_nuestros_servicios.subtitulo")}</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{t("seccion_nuestros_servicios.servicio_" + services.id)}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>

      <hr></hr>
    </section>
  );
}

export default AppServices;