
import './App.css';
import { BrowserRouter, Routes,Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import {useTranslation} from "react-i18next";


import Encabezado from './components/Encabezado';
import AppHero from './components/hero';
import AppBlog from './components/blog';
import AppWorks from './components/works';
import AppPricing from './components/pricing';
import AppServices from './components/services';
import AppAbout from './components/about';
import AppTestimonials from './components/testimonials';
import AppTeams from './components/teams';
import AppContact from './components/contact';
import AppFooter from './components/footer';



function App() {
  

  return (
    <div className="App">
      <header  id='header'>
        <Encabezado />
      </header>
      <main>
        <AppHero />
        <AppBlog />

        <AppWorks />
        <AppPricing />
        <AppServices />
        <AppAbout />
        <AppTestimonials />
        <AppTeams />
        <AppContact />

      </main>
      <footer id="footer">
      <AppFooter />
      </footer>
    </div>
  );
}

export default App;
